import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import axios from "axios";

import { ReactComponent as ArrowRightOutlined } from "../../../../assets/icons/arrowRightOutlined.svg";
import { ReactComponent as LinkedinFilled } from "../../../../assets/icons/linkedinFilled.svg";
import RetailOnboardingContainer from "../../../../components/Container/RetailOnboardingContainer";
import img from "../../../../assets/images/onboarding/first-step.png";
import RetailTitle from "../../../../components/Typography/RetailTitle";
import RetailText from "../../../../components/Typography/RetailText";
import RetailMainButton from "../../../../components/Button/RetailMainButton";
import RetailFormInput from "../../../../components/Form/RetailFormInput";
import RetailSelect from "../../../../components/Select/RetailSelect";
import RetailNotification from "../../../../components/Notification";

import cm from "./style.module.scss";

export interface OnboardingSecondStepProps {
  setStep: () => void;
}

const OnboardingSecondStep = ({ setStep }: OnboardingSecondStepProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [form] = useForm();

  const isValidURL =
    /^(https?:\/\/)?([\w\u0600-\u06FF-]+\.)*[\w\u0600-\u06FF-]{2,}\.[a-z\u0600-\u06FF]{2,}(\/[\w\u0600-\u06FF-.,@?^=%&:/~+#]*)?$/;

  const [formFields, setFormFields] = useState({
    website: "",
    password: "",
    type: "",
  });

  const [linkeinUser, setLinkedinUser] = useState<{
    email: string;
    name: string;
    picture_url: string;
  } | null>(null);

  const [loading, setLoading] = useState(false);

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target;
    setFormFields((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleTypeChange = (value: string) =>
    setFormFields({ ...formFields, type: value });

  const createMarketplace = async () => {
    try {
      setLoading(true);
      const response = await axios.post("api/onboarding", {
        user_email: linkeinUser?.email,
        marketplace_url: formFields.website,
        user_password: formFields.password,
        user_password_verify: formFields.password,
        marketplace_type: formFields.type,
      });
      localStorage.setItem(
        "auth_token",
        JSON.stringify(response.data.jwt_token)
      );
      localStorage.setItem("onboarding_user", JSON.stringify(response.data));
      localStorage.setItem("sample", formFields.type);
      setStep();
      /* localStorage.removeItem("linkedin"); */
    } catch (error) {
      console.error(error);
      RetailNotification.showNotification(
        "error",
        t("pages.auth.signUp.err"),
        t("pages.auth.signUp.warning")
      );
    } finally {
      setLoading(false);
    }
  };

  const cleanStorage = () => {
    const localStorageKeysForCleaning = [
      "auth_token",
      "auth_email",
      "linkedin",
      "lastValidStep",
    ];
    localStorageKeysForCleaning.forEach((key) => localStorage.removeItem(key));
  };

  const changeAccount = () => {
    cleanStorage();
    navigate("/onboarding");
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pageNumber = searchParams.get("q") || "1";

  useEffect(() => {
    try {
      const storedValue = localStorage.getItem("linkedin");
      if (storedValue) {
        setLinkedinUser(JSON.parse(storedValue));
      }
    } catch (error) {
      console.error("Error parsing JSON from localStorage:", error);
    }
  }, []);

  return (
    <>
      <div className={`flex ${cm.container}`}>
        <section className={cm.side}>
          <article className={cm.userInfo}>
            {linkeinUser?.picture_url && (
              <img src={linkeinUser.picture_url} alt="Profile" />
            )}

            <div className={cm.infoTextContainer}>
              {linkeinUser?.name && (
                <RetailText className={cm.userName} size="xs" weight="medium">
                  {linkeinUser.name}
                </RetailText>
              )}
              {linkeinUser?.email && (
                <RetailText
                  className={cm.userEmail}
                  size="xxxs"
                  weight="medium"
                >
                  {linkeinUser.email}
                </RetailText>
              )}
            </div>
            <RetailMainButton
              hasBackground={false}
              className={cm.changeAccountBtn}
              onClick={changeAccount}
            >
              {t("pages.auth.onboarding.changeAccount")}
              <LinkedinFilled className={cm.linkedinIcon} />
            </RetailMainButton>
          </article>
          <RetailText size="xxxs" weight="medium" className={cm.pageNumber}>
            {pageNumber}/4
          </RetailText>
          <RetailTitle className={cm.title} level={3}>
            {t("pages.auth.onboarding.createTitle")}
          </RetailTitle>
          <RetailText className={cm.subtitle} size="xs" weight="medium">
            {t("pages.auth.onboarding.createSubtitle")}
          </RetailText>
          <Form
            form={form}
            requiredMark={false}
            className={cm.form}
            onFinish={createMarketplace}
            autoComplete="off"
            autoSave="off"
          >
            <RetailFormInput
              isFocused={formFields.website !== ""}
              label={t("pages.auth.onboarding.websiteLabel")}
              name="website"
              rules={[
                {
                  required: true,
                  pattern: isValidURL,
                },
              ]}
              className={`floating ${cm.inputItem}`}
              help={t("pages.auth.onboarding.websiteHelp")}
            >
              <Input
                onChange={handleChange}
                className="floating"
                name="website"
                value={formFields.website}
              />
            </RetailFormInput>
            <Form.Item
              label=""
              name="password"
              rules={[{ required: true }]}
              className={`${cm.inputItem} ${cm.passwordInput}`}
              help={t("pages.auth.onboarding.passwordHelp")}
            >
              <Input.Password
                onChange={handleChange}
                name="password"
                value={formFields.password}
                type="password"
                placeholder={t("pages.auth.onboarding.passwordLabel")}
              />
            </Form.Item>
            <Form.Item
              label={""}
              name="type"
              rules={[{ required: true }]}
              help={t("pages.auth.onboarding.typeHelp")}
              className={cm.selectItem}
            >
              <RetailSelect
                onChange={handleTypeChange}
                value={formFields.type}
                className={cm.select}
                placeholder={t("pages.auth.onboarding.typeLabel")}
                options={[
                  {
                    value: "CLOTHING",
                    label: t("pages.auth.onboarding.clothing"),
                  },
                  { value: "TECH", label: t("pages.auth.onboarding.tech") },
                  {
                    value: "GROCERY",
                    label: t("pages.auth.onboarding.grocery"),
                  },
                ]}
              />
            </Form.Item>

            <RetailMainButton
              className={cm.submitBtn}
              htmlType="submit"
              hasBackground
              loading={loading}
            >
              {t("pages.auth.onboarding.continue")}
              <ArrowRightOutlined />
            </RetailMainButton>
          </Form>
        </section>
      </div>
      <RetailOnboardingContainer
        text={t("pages.auth.onboarding.firstStepText")}
        sideImg={img}
        step="second"
      />
    </>
  );
};

export default OnboardingSecondStep;
