import { useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const OnboardingAPIPage = () => {
  const location = useLocation();

  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);

  const code = searchParams.get("code");
  const state = searchParams.get("state");

  const getEmail = useCallback(async () => {
    try {
      await axios
        .post(
          "/auth/linkedin/token",
          {
            state,
            code,
          },
          {
            withCredentials: true,
          }
        )
        .then(async (response) => {
          const hasUser = await axios.post("/api/users/has_user", {
            email: response.data.email,
          });
          localStorage.setItem("linkedin", JSON.stringify(response.data));
          localStorage.setItem(
            "auth_email",
            JSON.stringify(response.data.email)
          );
          document.cookie = `linkedin_state_key=f0d4ba37-57a3-4509-a10d-d6ece83902323; path=/; Secure; SameSite=Lax; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
          if (hasUser.data.result) navigate("/login");
          else {
            localStorage.setItem("lastValidStep", "2");
            navigate("/onboarding?q=2", { replace: true });
          }
        });
    } catch (error) {
      console.log(error);
    }
  }, [state, code, navigate]);

  useEffect(() => {
    getEmail();
  }, [getEmail]);

  return <></>;
};

export default OnboardingAPIPage;
