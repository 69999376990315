import cc from "classcat";
import {
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { ReactComponent as DownOutlined } from "../../../assets/icons/downOutlined.svg";
import { ReactComponent as EarthOutlined } from "../../../assets/icons/earthOutlined.svg";
import { ReactComponent as LogoutOutlined } from "../../../assets/icons/logoutOutlined.svg";
import { ReactComponent as RefreshOutlined } from "../../../assets/icons/refreshOutlined.svg";
import { ReactComponent as UserFilled } from "../../../assets/icons/userFilled.svg";
import {
	Auth,
	AuthContext,
} from "../../../context/AuthProvider";
import useSelectCloser from "../../../hooks/useSelectCloser";
import { bg_mode } from "../../../utils/defaults";
import CampaignDetailsModal from "../../Modal/CampaignDetailsModal";
import RetailSelect from "../../Select/RetailSelect";
import cm from "../style.module.scss";

const RetailNavbarPopover = () => {
  const { t, i18n } = useTranslation();

  const { logout, showAdminBar } = useContext(AuthContext) as Auth;

  const sso_mode = (window as any).marketplaceConfig?.sso_mode || false;

  const [visible, setVisible] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);

  const [lang, setLang] = useState(t(i18n.language).toUpperCase());

  const [accounts, setAccounts] = useState<any[]>([]);

  const ref = useRef<any>(null);

  const toggleVisible = () => setVisible(!visible);

  const handleLangChange = (lang: string) => setLang(lang);

  const onOk = () => {
    setModalVisible(false);
    i18n.changeLanguage(lang);
  };

  const onCancel = () => setModalVisible(false);

  const account_index = JSON.parse(
    localStorage.getItem("account_index") || "0"
  );

  const email = JSON.parse(localStorage.getItem("auth_email") || "null");

  useEffect(() => {
    const timer = setTimeout(() => {
      const accountsFromLocal = localStorage.getItem("auth_info");
      if (accountsFromLocal) {
        setAccounts(JSON.parse(accountsFromLocal));
      }
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  useSelectCloser(ref, setVisible);

  return (
    <section
      className={cc([
        cm.wrapper,
        visible ? cm.wrapperVisible : "",
        cm[bg_mode],
      ])}
      ref={ref}
    >
      <div className={cc([cm.overlay, visible ? cm.overlayVisible : ""])}></div>
      <div
        className={cc(["flex", cm.container, cm[bg_mode]])}
        onClick={toggleVisible}
      >
        <div className={cc(["flex", cm.userIcon, cm[bg_mode]])}>
          <UserFilled />
        </div>
        <article>
          <p className={cm.name}>{accounts[account_index]?.account_name}</p>
          {sso_mode ? null : <p className={cm.mail}>{email}</p>}
        </article>
        {visible ? (
          <DownOutlined className={cm.iconUp} />
        ) : (
          <DownOutlined className={cm.iconDown} />
        )}
      </div>

      {visible && (
        <ul
          className={cc([
            cm.list,
            showAdminBar ? cm.adminList : "",
            cm[bg_mode],
          ])}
        >
          {accounts?.length > 1 && (
            <li className="flex">
              <RefreshOutlined />
              <Link to="/accounts">
                {t("components.navbarPopover.account")}
              </Link>
            </li>
          )}
          <li className="flex" onClick={() => setModalVisible(true)}>
            <EarthOutlined />
            {t("components.navbarPopover.language")}
          </li>
          <li className="flex" onClick={logout}>
            <LogoutOutlined />
            {t("components.navbarPopover.logout")}
          </li>
        </ul>
      )}

      <CampaignDetailsModal
        type="SELECT_LANGUAGE"
        visible={modalVisible}
        onOk={onOk}
        onCancel={onCancel}
      >
        <RetailSelect
          className={cc([cm.select, "language"])}
          onChange={handleLangChange}
          defaultValue={t(i18n.language).toUpperCase()}
          dropdownClassName={cm.dropdown}
          options={[
            { value: "tr", label: "TR" },
            { value: "en", label: "EN" },
            { value: "ar", label: "AR" },
            { value: "ch", label: "CH" },
            { value: "jp", label: "JP" },
            { value: "kr", label: "KR" },
          ]}
        />
      </CampaignDetailsModal>
    </section>
  );
};

export default RetailNavbarPopover;
