import { TFunction, Trans, useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Radio, Upload, UploadProps } from "antd";
import { useForm } from "antd/es/form/Form";
import { AuthContext, Auth } from "../../../../context/AuthProvider";
import {
  Creative,
  CreativeContext,
} from "../../../../context/CreativeProvider";

import Form from "antd/lib/form";
import Input from "antd/lib/input";
import axios from "axios";

import RetailOnboardingContainer from "../../../../components/Container/RetailOnboardingContainer";
import RetailTitle from "../../../../components/Typography/RetailTitle";
import RetailText from "../../../../components/Typography/RetailText";
import RetailMainButton from "../../../../components/Button/RetailMainButton";
import RetailFormInput from "../../../../components/Form/RetailFormInput";
import RetailInfoTag from "../../../../components/Tag/RetailInfoTag";
import RetailNotification from "../../../../components/Notification";
import sideImg from "../../../../assets/images/onboarding/third-step.png";
import { ReactComponent as ArrowRightOutlined } from "../../../../assets/icons/arrowRightOutlined.svg";
import { ReactComponent as OnboardingCreativeUploadFilled } from "../../../../assets/icons/onboardingCreativeUploadFilled.svg";
import { ReactComponent as UploadedIcon } from "../../../../assets/icons/uploadedOutlined.svg";
import { ReactComponent as NotificationIconFilled } from "../../../../assets/icons/notificationIconFilled.svg";
import { DeleteOutlined, InfoCircleFilled } from "@ant-design/icons";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import { Img } from "../../../../utils/types";

import cm from "./style.module.scss";

export interface OnboardingThirdStepProps {
  setStep: () => void;
}

export interface VideoPlayerProps {
  src: string;
}

const VideoPlayer = ({ src }: VideoPlayerProps) => {
  return (
    <video autoPlay={true} muted={true} loop={true} className={cm.video}>
      <source src={src} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

export interface UploadBannerProps {
  t: TFunction;
  type: "AUTO" | "CUSTOM" | "SAMPLE";
  onboardingUser: any;
}

const UploadBanner = ({ t, type, onboardingUser }: UploadBannerProps) => {
  const { setImg } = useContext(CreativeContext) as Creative;

  const mainImgFile = useRef<File>();

  const handleFileChange = async (info: UploadChangeParam<UploadFile<any>>) => {
    const file = mainImgFile;

    if (!info || !info.file || info.fileList.length === 0) {
      file.current = undefined;
    }

    file.current = info.file ? (info.file as unknown as File) : undefined;

    const formData = new FormData();

    formData.append("image", file.current!);
    formData.append("name", file?.current?.name!);
    formData.append("status", "ACTIVE");
    formData.append("format", "IMAGE");

    const config = {
      image: formData.get("image"),
      body: JSON.stringify({
        name: formData.get("name"),
        status: formData.get("status"),
        format: formData.get("format"),
        skip_size_validation: type === "CUSTOM" ? true : null,
      }),
    };

    const id =
      onboardingUser?.advertiser_id || onboardingUser?.default_advertiser_id;

    try {
      await axios
        .post(`api/advertisers/${id}/creatives`, config, {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("auth_token") || ""
            )}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          const img = new Image();

          img.src = response.data.image_url;

          img.onload = () => {
            setImg((prev: Img[]) => [
              ...prev,
              {
                url: img.src,
                size: `${img.width}x${img.height}`,
                creative_name: response.data.creative_name,
                uuid: response.data.id,
                size_valid: response.data.size_valid,
              },
            ]);
          };
        });
    } catch (err) {
      const maxFileSize = err?.request?.responseText?.includes(
        "file size is too large"
      );
      const errKey = maxFileSize ? "creativeSizeErr" : "generalErr";

      if (errKey) {
        return RetailNotification.showNotification(
          "error",
          "",
          t(`components.campaignForm.firstStep.errorStates.${errKey}`)
        );
      }
      console.error(err);
    }
  };

  const props: UploadProps = {
    name: "files",
    multiple: false,
    className: `dragger ${cm.uploadBanner} ${cm[type.toLowerCase()]}`,
    itemRender: () => <></>,
    beforeUpload: () => false,
    onChange: (f) => handleFileChange(f),
    accept: ".jpg, .jpeg, .png",
  };

  return (
    <Upload.Dragger {...props}>
      <div className={`flex ${cm.innerUpload}`}>
        <OnboardingCreativeUploadFilled className={cm.upload} />
        <article>
          <RetailText size="xs" weight="medium" className={cm.uploadInnerTitle}>
            <Trans i18nKey="pages.auth.onboarding.uploadInnerTitle" />
          </RetailText>

          <RetailText
            size="xxxs"
            weight="medium"
            className={cm.uploadInnerText}
          >
            {t("pages.auth.onboarding.uploadInnerText")}
          </RetailText>
        </article>
      </div>
    </Upload.Dragger>
  );
};

export interface UploadedBannerProps {
  t: TFunction;
  img: Img;
  onClick: any;
  isAuto?: boolean;
}

const UploadedBanner = ({ t, img, onClick, isAuto }: UploadedBannerProps) => {
  const deleteBanner = () => onClick(img.uuid);

  if (img.size !== "970x50" && isAuto)
    return (
      <section className={cm.uploadedErrorWrapper}>
        <article className={cm.uploadedErrorContainer}>
          <RetailText size="xs" weight="bold" className={cm.errorTitle}>
            {t("pages.auth.onboarding.errorTitle")}
          </RetailText>
          <RetailText size="xxxs" weight="medium" className={cm.errorText}>
            {t("pages.auth.onboarding.errorText")}
          </RetailText>
          <RetailMainButton onClick={deleteBanner} className={cm.errorBtn}>
            {t("pages.auth.onboarding.errorBtn")}
          </RetailMainButton>
        </article>
        <RetailText
          size="xxxs"
          weight="medium"
          className={`flex ${cm.uploadedErrorText}`}
        >
          <NotificationIconFilled /> {t("pages.auth.onboarding.errorWarning")}
        </RetailText>
      </section>
    );

  return (
    <section className={`flex ${cm.uploadedContainer}`}>
      <UploadedIcon className={cm.uploadedIcon} />
      <article>
        <RetailText
          size="xs"
          weight="bold"
          className={cm.imgName}
        >{`${img.creative_name}`}</RetailText>
        <RetailText size="xxxs" weight="medium" className={cm.imgSize}>
          {img.size}
        </RetailText>
      </article>
      <div className={`flex ${cm.deleteContainer}`} onClick={deleteBanner}>
        <DeleteOutlined />
      </div>
    </section>
  );
};

const OnboardingThirdStep = ({ setStep }: OnboardingThirdStepProps) => {
  const { t } = useTranslation();

  const { authInfo } = useContext(AuthContext) as Auth;

  const { img, setImg, deleteCreative } = useContext(
    CreativeContext
  ) as Creative;

  const [form] = useForm();

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const pageNumber = searchParams.get("q") || "1";

  const [onboardingUser, setOnboardingUser] = useState<any>(null);

  const sample = localStorage.getItem("sample") || "TECH";

  const [formFields, setFormFields] = useState<{
    placement: "AUTO" | "CUSTOM" | null;
    is_sample: boolean;
    banner: string | null;
    redirect_url: string;
  }>({
    placement: null,
    is_sample: false,
    banner: null,
    redirect_url: "",
  });

  const isValidURL =
    /^(https?:\/\/)?([\w\u0600-\u06FF-]+\.)*[\w\u0600-\u06FF-]{2,}\.[a-z\u0600-\u06FF]{2,}(\/[\w\u0600-\u06FF-.,@?^=%&:/~+#]*)?$/;

  const isPlacementNull = formFields.placement === null;
  const isImageListEmpty = img.length === 0;
  const isAutoPlacementInvalid =
    formFields.placement === "AUTO" && img[0]?.size !== "970x50";
  const isRedirectUrlRequired =
    !formFields.is_sample && !isValidURL.test(formFields.redirect_url);

  const isButtonDisabled =
    isPlacementNull ||
    isImageListEmpty ||
    isAutoPlacementInvalid ||
    (isRedirectUrlRequired && !formFields.is_sample);

  const handlePlacementChange = ({ target }: any) => {
    setFormFields((prev) => ({
      ...prev,
      placement: target.value,
      is_sample: target.value === "AUTO",
    }));
    setImg([]);
  };

  const onChange = ({ target }: any) => {
    setFormFields((prev) => ({ ...prev, redirect_url: target.value }));
  };

  const chooseSample = () => {
    setFormFields((prev) => ({ ...prev, is_sample: true }));
  };

  const chooseUpload = () => {
    setFormFields((prev) => ({ ...prev, is_sample: false }));
    setImg([]);
  };

  const createCampaign = async () => {
    const id = onboardingUser?.marketplace_id || onboardingUser?.resource_id;
    try {
      const response = await axios.post(
        `api/onboarding/marketplaces/${id}/campaigns`,
        {
          status: "ACTIVE",
          creative: img[0].uuid,
          redirect_url: formFields.is_sample ? null : formFields.redirect_url,
        },
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("auth_token") || ""
            )}`,
          },
        }
      );
      localStorage.setItem(
        "ad_placement_id",
        JSON.stringify(response.data.ad_placement_id)
      );
      setImg([]);
      setStep();
    } catch (error) {
      console.error(error);
    }
  };

  const switchSample = useCallback(() => {
    switch (sample) {
      case "CLOTHING":
        return "./sample-banner-clothing.png";
      case "TECH":
        return "./sample-banner-tech.png";
      case "GROCERY":
        return "./sample-banner-grocery.png";
      default:
        return "./sample-banner-tech.png";
    }
  }, [sample]);

  const uploadImage = useCallback(async () => {
    const id =
      onboardingUser?.advertiser_id || onboardingUser?.default_advertiser_id;

    const response = await fetch(switchSample());
    const blob = await response.blob();

    const formData = new FormData();

    formData.append("image", blob);
    formData.append("name", "sample");
    formData.append("status", "ACTIVE");
    formData.append("format", "IMAGE");

    const config = {
      image: formData.get("image"),
      body: JSON.stringify({
        name: formData.get("name"),
        status: formData.get("status"),
        format: formData.get("format"),
      }),
    };

    await axios
      .post(`api/advertisers/${id}/creatives`, config, {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("auth_token") || ""
          )}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const img = new Image();

        img.src = response.data.image_url;

        img.onload = () => {
          setImg((prev: Img[]) => [
            ...prev,
            {
              url: img.src,
              size: `${img.width}x${img.height}`,
              creative_name: response.data.creative_name,
              uuid: response.data.id,
              size_valid: response.data.size_valid,
            },
          ]);
        };
      });
  }, [onboardingUser, setImg, switchSample]);

  useLayoutEffect(() => {
    const onboardingUser = localStorage.getItem("onboarding_user");
    const accountIndex = localStorage.getItem("account_index");

    if (accountIndex !== null && authInfo) {
      setOnboardingUser(authInfo[JSON.parse(accountIndex)]);
    } else if (onboardingUser !== null) {
      setOnboardingUser(JSON.parse(onboardingUser));
    }
  }, [authInfo]);

  useEffect(() => {
    if (!formFields.is_sample || !onboardingUser) return;
    else uploadImage();
  }, [formFields.is_sample, onboardingUser, uploadImage]);

  return (
    <>
      <section className={`flex ${cm.container}`}>
        <article className={cm.side}>
          <RetailText size="xxxs" weight="medium" className={cm.pageNumber}>
            {pageNumber}/4
          </RetailText>
          <RetailTitle className={cm.title} level={3}>
            {t("pages.auth.onboarding.campaignTitle")}
          </RetailTitle>
          <RetailText size="xs" weight="medium" className={cm.mainText}>
            {t("pages.auth.onboarding.campaignText")}
          </RetailText>

          <Radio.Group onChange={handlePlacementChange}>
            <Radio className={`form-radio ${cm.radio}`} value="AUTO">
              <RetailTitle level={5} className="flex">
                {t("pages.auth.onboarding.autoTitle")}
              </RetailTitle>
              <RetailText className={cm.radioText} size="xxs" family="poppins">
                {t("pages.auth.onboarding.autoText")}
              </RetailText>
              <RetailInfoTag
                className={cm.infoTag}
                icon={true}
                type="SETTINGS"
                closable={false}
              >
                <InfoCircleFilled />
                {t("pages.auth.onboarding.autoTag")}
              </RetailInfoTag>
              {formFields.placement === null && (
                <VideoPlayer src="auto-placement.mp4" />
              )}
              {formFields.placement === "AUTO" && (
                <section className={cm.autoInnerContainer}>
                  <div className={`flex ${cm.btnContainer}`}>
                    <RetailText
                      size="xxxs"
                      weight="medium"
                      className={`${cm.placementBtn} ${
                        formFields.is_sample ? cm.active : ""
                      }`}
                      onClick={chooseSample}
                    >
                      {t("pages.auth.onboarding.sampleBtn")}
                    </RetailText>
                    <RetailText
                      size="xxxs"
                      weight="medium"
                      className={`${cm.placementBtn} ${
                        !formFields.is_sample ? cm.active : ""
                      }`}
                      onClick={chooseUpload}
                    >
                      {t("pages.auth.onboarding.uploadBtn")}
                    </RetailText>
                  </div>
                  {(img.length === 0 || img[0]?.size === "970x50") && (
                    <RetailText
                      size="xxxs"
                      weight="medium"
                      className={cm.autoInnerText}
                    >
                      {formFields.is_sample
                        ? t("pages.auth.onboarding.sampleText")
                        : t("pages.auth.onboarding.uploadText")}
                    </RetailText>
                  )}
                  <></>
                  {formFields.is_sample ? (
                    <img
                      src={switchSample()}
                      alt="sample-banner"
                      className={cm.sampleImg}
                    />
                  ) : (
                    <>
                      {img.length === 0 ? (
                        <UploadBanner
                          t={t}
                          type="AUTO"
                          onboardingUser={onboardingUser}
                        />
                      ) : (
                        <UploadedBanner
                          t={t}
                          img={img[0]}
                          onClick={deleteCreative}
                          isAuto
                        />
                      )}
                      <Form
                        form={form}
                        requiredMark={false}
                        colon={false}
                        autoComplete="off"
                      >
                        <RetailFormInput
                          isFocused={formFields.redirect_url !== ""}
                          label={t("pages.auth.onboarding.redirectURL")}
                          name="bid"
                          className={`${cm.inputContainer} floating`}
                          help={t("pages.auth.onboarding.redirectHelp")}
                          rules={[
                            {
                              required: true,
                              pattern: isValidURL,
                            },
                          ]}
                        >
                          <Input
                            className={`${cm.input} floating`}
                            onChange={onChange}
                            value={formFields.redirect_url}
                          />
                        </RetailFormInput>
                      </Form>
                    </>
                  )}
                </section>
              )}
            </Radio>
            <Radio className={`form-radio ${cm.radio}`} value="CUSTOM">
              <RetailTitle level={5} className="flex">
                {t("pages.auth.onboarding.customTitle")}
              </RetailTitle>
              <RetailText className={cm.radioText} size="xxs" family="poppins">
                {t("pages.auth.onboarding.customText")}
              </RetailText>
              <RetailInfoTag
                className={cm.infoTag}
                icon={true}
                type="SETTINGS"
                closable={false}
              >
                <InfoCircleFilled />
                {t("pages.auth.onboarding.customTag")}
              </RetailInfoTag>
              {formFields.placement === null && (
                <VideoPlayer src="custom-placement.mp4" />
              )}
              {formFields.placement === "CUSTOM" ? (
                <>
                  {img.length === 0 ? (
                    <UploadBanner
                      t={t}
                      type="CUSTOM"
                      onboardingUser={onboardingUser}
                    />
                  ) : (
                    <UploadedBanner
                      t={t}
                      img={img[0]}
                      onClick={deleteCreative}
                    />
                  )}
                  <Form
                    form={form}
                    requiredMark={false}
                    colon={false}
                    autoComplete="off"
                  >
                    <RetailFormInput
                      isFocused={formFields.redirect_url !== ""}
                      label={t("pages.auth.onboarding.redirectURL")}
                      name="bid"
                      className={`${cm.inputContainer} floating`}
                      help={t("pages.auth.onboarding.redirectHelp")}
                      rules={[
                        {
                          required: true,
                          pattern: isValidURL,
                        },
                      ]}
                    >
                      <Input
                        className={`${cm.input} floating`}
                        onChange={onChange}
                        value={formFields.redirect_url}
                      />
                    </RetailFormInput>
                  </Form>
                </>
              ) : null}
            </Radio>
          </Radio.Group>
          <RetailMainButton
            hasBackground={true}
            className={cm.nextBtn}
            onClick={createCampaign}
            disabled={isButtonDisabled}
          >
            {t("pages.auth.onboarding.create")}
            <ArrowRightOutlined />
          </RetailMainButton>
        </article>
      </section>
      <RetailOnboardingContainer
        text={t("pages.auth.onboarding.thirdStepText")}
        sideImg={sideImg}
        step="third"
      />
    </>
  );
};

export default OnboardingThirdStep;
