import Input from "antd/lib/input";
import cc from "classcat";
import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

import useApi from "../../../../api";
import { ReactComponent as CheckOutlined } from "../../../../assets/icons/checkWhite.svg";
import { ReactComponent as CloseOutlined } from "../../../../assets/icons/closeOutlined.svg";
import { ReactComponent as PencilOutlined } from "../../../../assets/icons/pencilOutlined.svg";
import RetailMoneyColumn from "../../../../components/Column/RetailMoneyColumn";
import RetailNumberColumn from "../../../../components/Column/RetailNumberColumn";
import RetailPercentageColumn from "../../../../components/Column/RetailPercentageColumn";
import RetailStateColumn from "../../../../components/Column/RetailStateColumn";
import RetailStatusColumn from "../../../../components/Column/RetailStatusColumn";
import CampaignDetailsModal from "../../../../components/Modal/CampaignDetailsModal";
import RetailTable from "../../../../components/Table/RetailTable";
import SelectCategoriesTable from "../../../../components/Table/SelectCategoriesTable";
import RetailBidTooltip from "../../../../components/Tooltip/RetailBidTooltip";
import {
  Category,
  CategoryContext,
} from "../../../../context/CategoryProvider";
import useTableFetch from "../../../../hooks/useTableFetch";
import { keywordFilters } from "../../../../utils/filters";
import { validateNumber } from "../../../../utils/helpers";
import cm from "../KeywordsTable/style.module.scss";

export interface CategoriesTableProps {
  bidType: string;
  chosenMinBid: () => any;
}

const CategoriesTable = ({ bidType, chosenMinBid }: CategoriesTableProps) => {
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();

  const { api } = useApi();

  const { categories, setCategories } = useContext(CategoryContext) as Category;

  const { data } = useTableFetch("campaign", true, {}, "CATEGORIES");

  const queryClient = useQueryClient();

  const inputRef = useRef<any>(null);

  const [isVisible, setIsVisible] = useState(false);

  const [editing, setEditing] = useState(false);

  const [popupPositions, setPopupPositions] = useState({ top: 0, left: 0 });

  const [selected, setSelected] = useState<any>([]);

  const [error, setError] = useState(false);

  const [alreadySelectedData, setAlreadySelectedData] = useState([]);

  const isFixedBid = bidType === "FIXED";

  const handleBlur = () => setEditing(false);

  const edit = () => {
    setEditing(true);
    inputRef.current.focus();
  };

  const renderColumns = (col: string, value: any, records: any) => {
    switch (col) {
      case "status":
        return (
          <RetailStatusColumn
            records={records}
            url={`campaigns/${id}/relations`}
            relationType="CATEGORIES"
          />
        );
      case "bid":
        return isFixedBid ? (
          <div className="flex">
            <RetailMoneyColumn value={value} />
            <div
              className={cc([cm.iconContainer, cm.editContainer, "flex"])}
              onClick={(event: any) => {
                setSelected({ ...records, bid: value.toFixed(2) });
                edit();
                setPopupPositions({ top: event.clientY, left: event.clientX });
              }}
            >
              <PencilOutlined data-cy="edit-category-bid" />
            </div>
          </div>
        ) : (
          <span>{t("components.table.keywords.dynamic")}</span>
        );
      case "state":
        return <RetailStateColumn value={value} type="categories" />;
      case "impressions":
      case "clicks":
      case "viewable_impressions":
      case "RoAS":
      case "sale":
      case "direct_sale":
      case "indirect_sale":
        return <RetailNumberColumn value={value} />;
      case "CTR":
      case "ACoS":
      case "CVR":
        return <RetailPercentageColumn value={value} />;
      case "CPM":
      case "CPC":
      case "sale_amount":
      case "direct_sale_amount":
      case "indirect_sale_amount":
        return <RetailMoneyColumn value={value} />;
      case "spend":
        return <RetailMoneyColumn value={value} spendColumn={true} />;
      default:
        return value ? value : "-";
    }
  };

  const reset = () => {
    setCategories([]);
    setIsVisible(false);
  };

  const updateBid = async () => {
    try {
      await api.post(`campaigns/${id}/relations`, {
        campaign_categories: {
          status: selected.status,
          categories: [
            { id: selected.category_id, bid: parseFloat(selected.bid) },
          ],
        },
      });
      queryClient.refetchQueries({ active: true });
    } catch (error) {
      console.log(error);
    }
  };

  const updateValue = async () => {
    const config = {
      categories:
        categories.length > 0
          ? categories.map((category: any) => {
              return {
                id: category.id,
                bid:
                  bidType === "FIXED"
                    ? parseFloat(category.bid || category.suggested_bid)
                    : null,
              };
            })
          : null,
    };
    const response = await api.patch(`campaigns/${id}`, config);
    return response;
  };

  const { mutateAsync } = useMutation(updateValue);

  const addNegativeKeywords = async () => {
    await mutateAsync();
    queryClient.refetchQueries({ active: true });
    reset();
  };

  const onOk = () => addNegativeKeywords();

  const open = () => setIsVisible(true);

  useEffect(() => {
    setAlreadySelectedData(data?.data.records);
  }, [data?.data.records]);

  const handleOk = () => {
    handleBlur();
    updateBid();
  };

  useEffect(
    () =>
      setError(
        parseFloat(selected && selected.bid) >= parseFloat(chosenMinBid()) &&
          inputRef.current.input.value !== ""
      ),
    [selected, chosenMinBid]
  );

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) =>
    setSelected({ ...selected, bid: target.value });

  const tableConfig = {
    url: "advertisers/1/campaigns",
    isRelation: true,
    relationType: "CATEGORIES",
    id: id ? parseInt(id) : 1,
    filters: keywordFilters(t),
    isFixedBid,
    min: chosenMinBid(),
    renderColumns,
  };

  return (
    <>
      <RetailTable
        button={{
          title: t("pages.acc.campaignDetails.categoriesBtn"),
          onClick: open,
        }}
        placeholder={t("pages.acc.campaignDetails.categoriesPlaceholder")}
        tableConfig={tableConfig}
        addBtnVisible
      />
      <div
        className={cc([cm.editPopup, "flex", error ? "" : cm.error])}
        style={{
          opacity: editing ? "1" : "0",
          pointerEvents: editing ? "all" : "none",
          top: `${popupPositions.top}px`,
          left: `${popupPositions.left - 20}px`,
        }}
      >
        {!error && <RetailBidTooltip value={chosenMinBid()} type="general" />}

        <Input
          data-cy="category-table-edit-input"
          ref={inputRef}
          className={cc([cm.input, error ? "" : cm.error])}
          onKeyDownCapture={(e) => validateNumber(e, true)}
          onChange={handleChange}
          autoFocus={true}
          value={selected?.bid}
        />
        <div
          className={cc([
            cm.iconContainer,
            cm.okContainer,
            "flex",
            error ? "" : cm.error,
          ])}
          onClick={handleOk}
          data-cy="edit-ok-button"
        >
          <CheckOutlined />
        </div>
        <div
          className={cc([cm.iconContainer, cm.closeContainer, "flex"])}
          onClick={handleBlur}
        >
          <CloseOutlined />
        </div>
      </div>
      <CampaignDetailsModal
        visible={isVisible}
        subtitle={t("pages.acc.campaignDetails.categoriesSub")}
        onCancel={reset}
        onOk={onOk}
        type="CATEGORIES"
      >
        <SelectCategoriesTable
          isDynamic={isFixedBid}
          alreadySelectedData={alreadySelectedData}
        />
      </CampaignDetailsModal>
    </>
  );
};

export default CategoriesTable;
