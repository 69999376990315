const beymen_tr = {
  marketplaceDependent: {
    form: {
      budget_link:
        "/academy/blog/management/kategori-bazli-tiklama-maliyetleri",
      bid_link:
        "/academy/blog/management/teklif-nasil-belirlenir-teklif-turleri-nelerdir",
      frequency_link:
        "/academy/blog/measuring/gonderim-turu-nedir-zamanlanmis-rapor-nasil-olusturulur",
      report_type_link: "/academy/blog/measuring/ozel-rapor-nasil-olusturulur",
      product_link:
        "/academy/blog/optimization/urun-secimi-yaparken-nelere-dikkat-edilmeli",
    },
    campaign_form_budget_text:
      "Kampanyanızın bütçe tipini ve miktarını belirleyin. Harcamanız bütçenize ulaştığında kampanya gösterimi durur. Harcanacak birim maliyetler kategori bazlı değişiklik göstermektedir. Tıklama başına maliyet 5₺ olarak ücretlendirilir.",
    campaign_form_targeting_text:
      "Kampanya hedefleme türü Beymen tarafından otomatik olarak belirlenecektir.",
    invoice_modal_help_text:
      "Ödeme yöntemi seç butonuna tıklayarak devam ettiğiniz takdirde tahsil edilecek tutarın iadesi yapılmayacaktır.",
    invoice_general_error_modal_title: "Bakiye Yüklenemedi!",
    invoice_general_error_modal_text:
      "Bakiye yüklemede aldığınız hata için Satıcı Destek üzerinden ''Kampanya ve Reklam Yönetimi'' başlığına talep açabilirsiniz.",
  },
};

export default beymen_tr;
