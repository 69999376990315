import axios from "axios";
import jwt from "jwt-decode";
import { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";

import { marketplaceName } from "../../utils/defaults";
import { Account } from "../../utils/types";

export type Auth = {
  authToken: string;
  setAuthToken(token: string): void;
  authInfo: any;
  setAuthInfo(data: any): void;
  logout(): void;
  showAdminBar: boolean;
  accountIndex: string;
  sso: string | undefined;
  navigateDependingOnAccount(account: Account): void;
  getUser(token: string): void;
  advValue: string | null;
};

export const AuthContext = createContext<Auth | null>(null);

export const AuthProvider: React.FC = ({ children }) => {
  const { pathname } = useLocation();

  let navigate = useNavigate();

  const [authToken, setAuthToken] = useState("");

  const [authInfo, setAuthInfo] = useState<any>(null);

  const accountIndex = localStorage.getItem("account_index") || "0";

  const sso = decodeURIComponent(window.location.href).split("token=")[1];

  const queryParams = new URLSearchParams(window.location.search);

  const advValue = queryParams.get("adv");

  const showAdminBar = advValue !== null;

  const onboardingRedirect = (step: string) => {
    localStorage.setItem("lastValidStep", step);
    navigate(`/onboarding?q=${step}`);
  };

  const navigateDependingOnAccount = (account: Account) => {
    if (account.account_type === "MARKETPLACE") {
      switch (account.onboarding_status) {
        case "NO_CAMPAIGN":
          return onboardingRedirect("3");
        case "WAITING_FOR_TRAFFIC":
          return onboardingRedirect("4");
        default:
          return navigate("/admin/campaigns");
      }
    } else navigate("/campaigns");
  };

  const handleLoginSuccess = (token: any, response: any) => {
    setAuthToken(token);
    setAuthInfo(response.data.accounts);
    localStorage.setItem("auth_token", JSON.stringify(token));
    localStorage.setItem("auth_email", JSON.stringify(response.data.email));
    if (response.data.accounts === null)
      navigate("/accounts", { state: { empty: true } });
    //If the user has more than one account, navigate to accounts page
    if (response.data.accounts?.length > 1 && !sso) navigate("/accounts");
    else {
      localStorage.setItem("account_index", JSON.stringify(0));
      navigateDependingOnAccount(response.data.accounts[0]);
    }
  };

  const getUser = async (token: any) => {
    const data = jwt(token) as any;
    try {
      const response = await axios.get(`/api/users/${data.user_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      handleLoginSuccess(token, response);
      localStorage.setItem("auth_info", JSON.stringify(response.data.accounts));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("auth_token");
    const auth_info = localStorage.getItem("auth_info");

    if (token !== null) setAuthToken(JSON.parse(token));
    if (token !== null) setAuthInfo(JSON.parse(auth_info!));
  }, []);

  useEffect(() => {
    //Special Teknosa paths
    const paths = ["/", "/case-study", "/case-study/"];
    //Auth paths
    const authPaths = [
      "/signup",
      "/forget-password",
      "/new-password",
      "/welcome",
      "/onboarding",
      "/onboarding/linkedin/callback",
    ];

    // Check for user authentication
    const isUserLoggedIn =
      localStorage.getItem("auth_token") !== null &&
      localStorage.getItem("auth_info") !== null;

    //Navigate to login page if a user is not logged in, except Teknosa paths and auth paths
    if (!isUserLoggedIn) {
      if (
        authPaths.includes(pathname) ||
        (marketplaceName === "Teknosa" && paths.includes(pathname))
      )
        return;
      navigate("/login");
      cleanStorage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cleanStorage = () => {
    const localStorageKeysForCleaning = [
      "auth_token",
      "auth_email",
      "auth_info",
      "advertiser_id",
      "account_index",
      "_grecaptcha",
      "onboarding_user",
      "ad_placement_id",
      "sample",
      "linkedin",
      "lastValidStep",
    ];
    localStorageKeysForCleaning.forEach((key) => localStorage.removeItem(key));
  };

  const logout = async () => {
    try {
      await axios.get("/auth/logout", {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      navigate("/login");
      setAuthToken("");
      setAuthInfo(null);
      cleanStorage();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        authToken,
        setAuthToken,
        authInfo,
        setAuthInfo,
        logout,
        showAdminBar,
        accountIndex,
        sso,
        navigateDependingOnAccount,
        getUser,
        advValue,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
