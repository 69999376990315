import { useContext, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Auth, AuthContext } from "../../../../context/AuthProvider";
import { useLocation, useNavigate } from "react-router-dom";
import RetailMainButton from "../../../../components/Button/RetailMainButton";
import RetailText from "../../../../components/Typography/RetailText";
import RetailOnboardingContainer from "../../../../components/Container/RetailOnboardingContainer";
import { ReactComponent as ArrowRightOutlined } from "../../../../assets/icons/arrowRightOutlined.svg";
import { ReactComponent as LinkedInFilled } from "../../../../assets/icons/linkedinFilled.svg";
import { ReactComponent as WavyUnderlineFilled } from "../../../../assets/icons/wavyUnderlineFilled.svg";
import img from "../../../../assets/images/onboarding/first-step.png";
import cc from "classcat";

import cm from "./style.module.scss";

export interface OnboardingFirstStepProps {
  setStep: () => void;
}

const OnboardingFirstStep = ({ setStep }: OnboardingFirstStepProps) => {
  const { t } = useTranslation();

  const { authToken } = useContext(AuthContext) as Auth;

  const navigate = useNavigate();

  useEffect(() => {
    document.cookie =
      "linkedin_state_key=f0d4ba37-57a3-4509-a10d-d6ece83902323; path=/; Secure; SameSite=Lax";
    if (authToken !== "") navigate("/login");
  }, [authToken, navigate]);

  const signInWithLinkedIn = () => {
    const origin = window.location.origin;
    window.location.href = `${origin}/auth/linkedin/authorize`;
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pageNumber = searchParams.get("q") || "1";

  return (
    <>
      <section className={cc(["flex", cm.container])}>
        <article className={cm.textContainer}>
          <RetailText size="xxxs" weight="medium" className={cm.pageNumber}>
            {pageNumber}/4
          </RetailText>
          <div className={cm.firstLine}>
            <RetailText weight="bold">
              {t("pages.auth.onboarding.login")}
            </RetailText>
            <RetailText weight="medium">
              {t("pages.auth.onboarding.to")}
            </RetailText>
            <RetailText weight="bold" className={cm.greenText}>
              {t("pages.auth.onboarding.retail")}
              <WavyUnderlineFilled className={cm.wavyUnderline} />
            </RetailText>
          </div>
          <RetailText className={cm.secondLine}>
            <Trans i18nKey="pages.auth.onboarding.infrastructure" />
          </RetailText>
          <RetailMainButton
            className={cm.linkedinBtn}
            hasBackground={true}
            onClick={signInWithLinkedIn}
            disabled={authToken !== ""}
          >
            <LinkedInFilled />
            <span> {t("pages.auth.onboarding.linkedInButton")}</span>
            <ArrowRightOutlined />
          </RetailMainButton>
          <RetailText family="poppins" size="xxxs" className={cm.secondaryText}>
            {t("pages.auth.onboarding.linkedInTextAccept")}
          </RetailText>
        </article>
      </section>
      <RetailOnboardingContainer
        text={t("pages.auth.onboarding.firstStepText")}
        sideImg={img}
        step="first"
      />
    </>
  );
};

export default OnboardingFirstStep;
