import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import useApi from "../api";

export const useInfiniteScroll = (
  type:
    | "advertisers"
    | "categories"
    | "parent_categories"
    | "ad_placements/sizes"
    | "agencies"
    | "products"
    | "country"
    | "timezone"
    | "currency",
  enabled = true,
  isAdvertiserFilter = true,
  adTypeFromApi?: string
) => {
  const { api, baseURL } = useApi();

  const location = useLocation();

  const adTypeFromLocationState = location.state as { ad_type: string };

  const [search, setSearch] = useState("");

  const [debouncedSearch, setDebouncedSearch] = useState("");

  const [options, setOptions] = useState<any[]>([]);

  const [pageSize, setPageSize] = useState(10);

  const [total, setTotal] = useState(0);

  const [isEnabled, setIsEnabled] = useState(false);

  const [abortController, setAbortController] =
    useState<AbortController | null>(null);

  const ad_type = adTypeFromApi || adTypeFromLocationState?.ad_type;

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
    }, 300);
    return () => {
      clearTimeout(handler);
      abortController?.abort();
    };
  }, [search, abortController]);

  const handleKey = ({ target }: any) => setSearch(target.value);

  const handleScroll = ({ target }: any) => {
    const { scrollTop, scrollHeight, clientHeight } = target;

    if (total <= options.length) return;

    if (
      Math.round(scrollHeight - scrollTop) - 5 ===
      Math.round(clientHeight) - 5
    )
      setPageSize((prev) => prev + 10);
  };

  const filter = () => {
    if (type === "advertisers" && isAdvertiserFilter)
      return [{ key: "advertiserStatus", op: "eq", value: "ACTIVE" }];
    else if (type === "parent_categories")
      return [{ key: "is_main", op: "eq", value: "true" }];
    else return null;
  };

  const sort = type === "advertisers" ? { advertiser_name: "asc" } : null;

  const isCatalog =
    type === "country" || type === "timezone" || type === "currency";

  const url = () => {
    if (type === "parent_categories") return "categories/table";
    else if (type === "categories")
      return `categories/table?campaignType=${ad_type}`;
    else if (isCatalog) return "/catalog";
    else return `${type}/table`;
  };

  const fetchData = async () => {
    const config = {
      page: 1,
      page_size: pageSize,
      search: debouncedSearch,
      filters: filter(),
      sort: sort,
    };
    const catalogConfig = {
      ...config,
      query_type: type,
    };

    // Create a new AbortController
    const controller = new AbortController();
    setAbortController(controller);
    try {
      const response = await api.post(
        url(),
        isCatalog ? catalogConfig : config,
        {
          signal: controller.signal,
        }
      );
      setTotal(response.data.total_records);
      setOptions(response.data.records);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (baseURL() !== undefined) setIsEnabled(enabled);
  }, [enabled, baseURL]);

  useEffect(() => {
    if (type !== null && isEnabled) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, debouncedSearch, isEnabled]);

  return {
    options,
    pageSize,
    handleScroll,
    fetchData,
    search,
    setSearch,
    handleKey,
  };
};
