import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Spin } from "antd";
import { AuthContext, Auth } from "../../../../context/AuthProvider";

import axios from "axios";
import "highlight.js/styles/night-owl.css";
import Highlight from "react-highlight";
import RetailTitle from "../../../../components/Typography/RetailTitle";
import RetailText from "../../../../components/Typography/RetailText";
import RetailMainButton from "../../../../components/Button/RetailMainButton";
import RetailOnboardingContainer from "../../../../components/Container/RetailOnboardingContainer";
import RetailSuccessModal from "../../../../components/Modal/RetailSuccessModal";
import RetailTooltip from "../../../../components/Tooltip/RetailTooltip";

import { ReactComponent as ArrowRightOutlined } from "../../../../assets/icons/arrowRightOutlined.svg";
import { ReactComponent as CopyOutlined } from "../../../../assets/icons/copyOutlined.svg";
import { ReactComponent as ThunderFilled } from "../../../../assets/icons/thunderFilled.svg";
import { ReactComponent as RefreshOutlined } from "../../../../assets/icons/refreshOutlined.svg";
import img from "../../../../assets/images/onboarding/fourth-step.png";

import cc from "classcat";
import cm from "./style.module.scss";

const OnboardingFourthStep = () => {
  const { t } = useTranslation();

  const { getUser, authInfo } = useContext(AuthContext) as Auth;

  const [onboardingUser, setOnboardingUser] = useState<any>(null);

  const [token, setToken] = useState<string>("");

  const [adPlacementIDFromLocal, setAdPlacementIDFromLocal] =
    useState<string>("");

  const [uuid, setUUID] = useState<string>("");

  const [loading, setLoading] = useState(false);

  const [status, setStatus] = useState(false);

  const [visible, setVisible] = useState(false);

  const [isCopied, setIsCopied] = useState(false);

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const pageNumber = searchParams.get("q") || "1";

  useEffect(() => {
    const tokenFromStorage = localStorage.getItem("auth_token");
    if (tokenFromStorage) {
      try {
        setToken(JSON.parse(tokenFromStorage));
      } catch (error) {
        console.error("Error parsing token:", error);
      }
    }
  }, []);

  useEffect(() => {
    const accountIndex = localStorage.getItem("account_index");

    const onboardingUserFromLocal = localStorage.getItem("onboarding_user");

    if (accountIndex !== null && authInfo) {
      setOnboardingUser(authInfo[JSON.parse(accountIndex)]);
    } else if (onboardingUserFromLocal !== null) {
      setOnboardingUser(JSON.parse(onboardingUserFromLocal));
    }
  }, [authInfo]);

  const checkConnection = useCallback(async () => {
    const id = onboardingUser?.marketplace_id || onboardingUser?.resource_id;

    if (id === undefined && token === "") return;

    try {
      const response = await axios.get(
        `/api/onboarding/marketplaces/${id}/check_traffic`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.result) setStatus(true);
    } catch (error) {
      console.error(error);
    }
  }, [onboardingUser, token]);

  useEffect(() => {
    const getSettings = async () => {
      const id = onboardingUser?.marketplace_id || onboardingUser?.resource_id;

      if (id === undefined && token === "" && adPlacementIDFromLocal === "")
        return;

      try {
        setLoading(true);
        const response = await axios.get(`/api/marketplaces/${id}/settings`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUUID(response?.data?.uuid);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    getSettings();
    checkConnection();
  }, [onboardingUser, checkConnection, token, adPlacementIDFromLocal]);

  useEffect(() => {
    const handleStorageEvent = (event: StorageEvent) => {
      if (event.key === "ad_placement_id") {
        setAdPlacementIDFromLocal(
          onboardingUser?.default_ad_placement_id || event.newValue || ""
        );
      }
    };

    window.addEventListener("storage", handleStorageEvent);

    const currentAdPlacementID = localStorage.getItem("ad_placement_id");
    setAdPlacementIDFromLocal(
      onboardingUser?.default_ad_placement_id || currentAdPlacementID || ""
    );

    return () => {
      window.removeEventListener("storage", handleStorageEvent);
    };
  }, [onboardingUser]);

  useEffect(() => {
    if (!status) {
      const interval = setInterval(() => {
        checkConnection();
      }, 5000);
      return () => clearInterval(interval);
    }
  });

  const open = () => setVisible(true);

  const cleanStorage = () => {
    const localStorageKeysForCleaning = [
      "onboarding_user",
      "ad_placement_id",
      "linkedin",
      "lastValidStep",
      "sample",
    ];
    localStorageKeysForCleaning.forEach((key) => localStorage.removeItem(key));
  };

  const finishOnboarding = async () => {
    try {
      getUser(token);
      cleanStorage();
    } catch (error) {
      console.error(error);
    }
  };

  const code = `<script type="text/javascript">
  (function () {
    if (!window.top.document.getElementById("retail-ads-library")) {
      var e = document.createElement("script");
      e.type = "text/javascript";
      e.defer = true;
      e.id = "retail-ads-library";
      e.src = "https://sdk.gowit.com/index.js";
      window.top.document.getElementsByTagName("head")[0].appendChild(e);
    }
    (window.top.retail_ads = window.top.retail_ads || []).push({
      ad_type: "banner",
      api_url: "${window.location.origin}",
      marketplace_id: "${uuid}",
      page_number: 1,
      placements: [{ 
        // You can replace with your specific placement ID
        placement_id: ${adPlacementIDFromLocal}, 
      }],
      adFailed: function () {
        console.log("Ad Failed To Load");
      },
      adLoaded: function () {
        console.log("Ad Loaded");
      },
    });
  })();
</script>
`;

  if (loading) return <Spin size="large" spinning={loading} />;

  const copyTextToClipboard = () => navigator.clipboard.writeText(code);

  const handleCopyClick = () => {
    copyTextToClipboard()
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <section className={cc(["flex", cm.container])}>
        <article className={cm.textContainer}>
          <RetailText size="xxxs" weight="medium" className={cm.pageNumber}>
            {pageNumber}/4
          </RetailText>
          <RetailTitle className={cm.title} level={3}>
            {t("pages.auth.onboarding.embedCodeTitle")}
          </RetailTitle>
          <RetailText size="xs" weight="medium" className={cm.mainText}>
            {t("pages.auth.onboarding.embedCodeText")}
          </RetailText>
          <section className={cm.codeWrapper}>
            <div className={cm.codeContainer}>
              <Highlight className="javascript">{code}</Highlight>
            </div>
            <RetailTooltip
              title={
                isCopied
                  ? t("pages.admin.integrationInfo.copied")
                  : t("pages.admin.integrationInfo.copy")
              }
              trigger="hover"
            >
              <RetailText
                size="xxxs"
                weight="medium"
                className={cm.copyText}
                onClick={handleCopyClick}
              >
                Copy
                <CopyOutlined className={cm.copy} />
              </RetailText>
            </RetailTooltip>
          </section>
          <div
            className={cc([
              cm.activityWrapper,
              status ? cm.activityWrapperLive : cm.activityWrapperPending,
            ])}
          >
            <RetailText size="xs" weight="medium">
              {t("pages.auth.onboarding.activityTitle")}
            </RetailText>
            <RetailText size="xs" weight="bold" className={cm.autoText}>
              {t(`pages.auth.onboarding.${status ? "live" : "pending"}`)}
            </RetailText>
            <ThunderFilled />
          </div>
          <section className={cc(["flex", cm.connectionContainer])}>
            <RetailText size="xs" weight="medium" className={cm.connectionText}>
              {t("pages.auth.onboarding.connectionText")}
            </RetailText>
            <RetailMainButton
              hasBackground={false}
              className={cm.connectionBtn}
              onClick={checkConnection}
            >
              {t("pages.auth.onboarding.checkConnection")}
              <RefreshOutlined />
            </RetailMainButton>
          </section>

          <RetailMainButton hasBackground disabled={!status} onClick={open}>
            {t("pages.auth.onboarding.finishOnboarding")}
            <ArrowRightOutlined />
          </RetailMainButton>
        </article>
      </section>
      <RetailOnboardingContainer
        text={t("pages.auth.onboarding.fourthStepText")}
        sideImg={img}
        step="fourth"
      />
      <RetailSuccessModal
        type="onboarding"
        visible={visible}
        setVisible={setVisible}
        onClick={finishOnboarding}
      />
    </>
  );
};

export default OnboardingFourthStep;
