const koctas_tr = {
  marketplaceDependent: {
    form: {
      targeting_link:
        "/academy/blog/targeting/hedefleme-nasil-yapilir-hedefleme-turleri-nelerdir",
      bid_link:
        "/academy/blog/management/teklif-nasil-belirlenir-teklif-turleri-nelerdir",
      frequency_link:
        "/academy/blog/measuring/gonderim-turu-nedir-zamanlanmis-rapor-nasil-olusturulur",
      report_type_link: "/academy/blog/measuring/ozel-rapor-nasil-olusturulur",
      product_link:
        "/academy/blog/optimization/urun-secimi-yaparken-nelere-dikkat-edilmeli",
    },
    campaign_form_targeting_text:
      "Kampanyanızın hedefleme türünü seçin. Kampanyanız hedefleme tercihinize göre müşterilere gösterilir.",
    campaign_form_budget_text:
      "Kampanyanızın bütçe tipini ve miktarını belirleyin. Harcamanız bütçenize ulaştığında kampanya gösterimi durur.",
    invoice_modal_help_text:
      "Ödeme yöntemi seç butonuna tıklayarak devam ettiğiniz takdirde tahsil edilecek tutarın iadesinin yapılmayacağını onaylamış kabul edilirsiniz.",
    invoice_general_error_modal_title: "Bakiye Yüklenemedi!",
    invoice_general_error_modal_text: "Cüzdanınızda yeterli bakiye bulunmuyor.",
  },
  marketplaceDependentTable: {
    CPC: "Tıklama Maliyet",
    CPM: "1000 Görüntüleme Maliyet",
    RoAS: "Harcama Getirisi",
  },
};

export default koctas_tr;
