import { useContext, useEffect, useLayoutEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Auth, AuthContext } from "../../../context/AuthProvider";

import OnboardingFirstStep from "./OnboardingFirstStep";
import OnboardingSecondStep from "./OnboardingSecondStep";
import OnboardingThirdStep from "./OnboardingThirdStep";
import OnboardingFourthStep from "./OnboardingFourthStep";

import cm from "./style.module.scss";

const OnboardingPage = () => {
  const { authInfo } = useContext(AuthContext) as Auth;

  const location = useLocation();
  const navigate = useNavigate();

  const account_index = JSON.parse(
    localStorage.getItem("account_index") || "0"
  );
  const searchParams = new URLSearchParams(location.search);
  const q = searchParams.get("q") || "1";
  const parsedQ = parseInt(q, 10);

  useEffect(() => {
    const lastValidStep = localStorage.getItem("lastValidStep") || "";
    if (lastValidStep === "") {
      localStorage.setItem("lastValidStep", q);
      return;
    }
    navigate(`/onboarding?q=${lastValidStep}`, { replace: true });
  }, [parsedQ, navigate, q]);

  const updateSearchParam = () => {
    localStorage.setItem("lastValidStep", (parsedQ + 1).toString());
    searchParams.set("q", (parsedQ + 1).toString());
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  const handleRender = () => {
    if (parsedQ > 4) return;
    switch (q) {
      case "1":
        return <OnboardingFirstStep setStep={updateSearchParam} />;
      case "2":
        return <OnboardingSecondStep setStep={updateSearchParam} />;
      case "3":
        return <OnboardingThirdStep setStep={updateSearchParam} />;
      case "4":
        return <OnboardingFourthStep />;
      default:
        return <OnboardingFirstStep setStep={updateSearchParam} />;
    }
  };

  useLayoutEffect(() => {
    if (!authInfo || !account_index) return;
    if (authInfo[account_index]?.onboarding_status === "COMPLETE")
      navigate("/admin/campaigns");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authInfo, account_index]);

  useEffect(() => {
    const handleBack = (event: any) => event.preventDefault();
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", handleBack);

    return () => window.removeEventListener("popstate", handleBack);
  }, []);

  return <section className={cm.wrapper}>{handleRender()}</section>;
};

export default OnboardingPage;
